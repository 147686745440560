<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs"></slot>
    </template>
    <v-card :loading="loading" :disabled="loading ">
      <v-card-title>
        {{ $t('New Payment Method') }}
      </v-card-title>

      <v-img :src="images.card" class="mx-16 my-5"></v-img>
      <v-card-text class="pt-3">
        <stripe-element-card
            v-if="dialog"
            ref="elementRef"
            :pk="pk"
            @token="tokenCreated"
            class="mb-5"
            hidePostalCode
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            text
            @click="dialog = false"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
            @click="submit"
            color="primary">
          {{ $t('Add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";
import axios from '@axios'
import {StripeElementCard} from '@vue-stripe/vue-stripe';
import appConfig from "../../../../appConfig";
import {onBeforeMount, ref} from "@vue/composition-api";
import store from "@/store";

export default {
  components: {
    StripeElementCard,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const pk = appConfig.stripePubKey
    const dialog = ref(false)
    const loading = ref(false)
    const submit = () => {
      vm.$refs.elementRef.submit();
    }

    const tokenCreated = (token) => {
      loading.value = true
      axios.post("/payment-method-create", {card_token: token.id, payment_method: "stripe"}).then(res => {
        vm.$notify({
          group: 'deals',
          title: 'PM',
          text: vm.$t('New payment method added successfully'),
          type: 'success'
        })
        store.dispatch('payment/getPaymentMethods')
        loading.value = false
        dialog.value = false
      })
          .catch(() => loading.value = false)
    }
    return {
      dialog,
      tokenCreated,
      submit,
      loading,
      pk,
      images: {
        card: require('@/assets/images/app/card.png')
      }
    }
  }
};
</script>