<template>
  <v-card :disabled="balanceLoading" :loading="balanceLoading" class="mt-5">
    <v-card-text class="font-weight-semibold pb-0 pt-2">
      <v-checkbox
          v-model="useBalance"
          :label="`${$t('Use Deals Balance')} / ${balance} USD`"
      ></v-checkbox>
    </v-card-text>
    <v-card-text>
      {{ $t('Your Deals Balance will be automatically deducted') }}
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import {computed} from "@vue/composition-api";

export default {
  name: "BalanceInCheckout",
  setup() {
    const balanceLoading = computed(() => store.state.account.balanceLoading)
    const useBalance = computed({
      get: () => store.state.cart.useBalance,
      set: value => {
        store.dispatch('cart/setUseBalance', value)
      }
    })
    const balance = computed(() => store.state.account.balance)
    return {
      balance,
      balanceLoading,
      useBalance
    }
  }
}
</script>
