<template>
<div>
  <!-- <breadcrumbs /> -->
  <v-row>
    <v-col md="9" class="checkout-cart-column">
      <alert-connect-amazon :closeEv="false" :loadConnect="true"/>
      <v-card>
        <v-card-title>
          {{ $t('Shopping cart') }}<!--<span v-if="selectedCount" class="count-selected">{{ selectedCount }}</span>-->
        </v-card-title>
        <v-alert type="error" text v-if="duplicates.length">
          {{ $t('You have duplicated products in the shopping cart') }}
        </v-alert>
        <cart-table></cart-table>
      </v-card>
      <balance-in-checkout></balance-in-checkout>
      <payment-methods></payment-methods>
    </v-col>
    <v-col lg="3" md="3" sm="12">
      <checkout></checkout>
    </v-col>
  </v-row>
</div>
</template>
<script>
import AlertConnectAmazon from '@/views/market/components/AlertConnectAmazon'
import CartTable from './CartTable'
import Checkout from './Checkout'
import BalanceInCheckout from './BalanceInCheckout'
import PaymentMethods from './PaymentMethods'
import {computed} from "@vue/composition-api";
import store from "@/store";
export default {
  name: "Cart",
  components: {
    CartTable,
    Checkout,
    BalanceInCheckout,
    PaymentMethods,
    AlertConnectAmazon
  },
  setup() {
    const duplicates = computed(() => store.state.cart.duplicates)
    const selectedCount = computed(() => store.state.cart.selectedCartItems.length)
    return {
      duplicates,
      selectedCount
    }
  }
}
</script>
