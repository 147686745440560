<template>
  <v-data-table
      v-model="selected"
      :headers="headers"
      checkbox-color="primary"
      item-key="id"
      show-select
      :items="productList"
      :options.sync="options"
      :server-items-length="totalProducts"
      :loading="loading"
      :mobile-breakpoint="100"
      :footer-props="footerProps"
      class="cart-products-table"
      :item-class="checkDuplicates"
      :hide-default-footer="true"
      disable-pagination
  >
    <template v-slot:no-data>
      <div class="deals-cart-empty">
        <v-icon
          class="mt-2"
          size="100"
          color="grey-400"
        >
          {{ icons.mdiCart }}
        </v-icon></br>
        <span class="d-block mb-2 font-weight-bold grey-400--text">
          {{ $t('Cart is empty') }}
        </span>
      </div>
    </template>
    <template v-slot:loading>
      <v-skeleton-loader type="table-row-divider@2" style="width: 100%"></v-skeleton-loader>
    </template>
    <template #[`header.profit_fba`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.price`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.bsr_avg30`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.restricted`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.fba_offers`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.store`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`item.id`]="{item}">
      <router-link :to="{name: 'product', params:{id: item.id}}">{{ item.id }}</router-link>
    </template>
    <template #[`item.status`]="{item}">
      <v-chip
        small
        :color="statusColor[status[item.status]]"
        :class="`${statusColor[status[item.status]]}--text`"
        class="v-chip-light-bg"
      >
        {{ status[item.status] }}
      </v-chip>
    </template>
    <template #[`item.keepa_image`]="{item}">
    <router-link :to="{name: 'product', params: {id: item.id}}">
        <v-img
          max-height="40"
          max-width="80"
          style="margin-right: 0;box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;border-radius: 5px"
          :src="item.keepa_image||noKeepaImage"
        />
      </router-link>
    </template>
    <template #[`item.roi_fba`]="{item}">
      <span v-if="item.roi_fba">{{ item.roi_fba }}%</span>
    </template>
    <template #[`item.bsr_avg30`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip v-if="item.bsr_avg30" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              small
              label
              v-bind="attrs"
              v-on="on"
              style="min-width: 40px; justify-content: center; max-width: fit-content"
              :color="item.bsr_avg30 > 0? 'success':'error'"
              :class="item.bsr_avg30 > 0? 'success--text':'error--text'"
              class="v-chip-light-bg mb-1"
            >
              {{ item.bsr_avg30 | numberWithSpaces }}
            </v-chip>
          </template>
          <span>{{ '90d avg BSR'}}</span>
        </v-tooltip>
        <v-tooltip v-if="item.category" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.category}}
            </span>
          </template>
          <span>{{ 'Category'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.sales_rank_drops30`]="{item}">
      {{ `${Number(item.sales_rank_drops30)}` }}
    </template>
    <template #[`item.asin`]="{item}">
      <a :href="item.product_url" target="_blank">{{ item.asin }}</a>
    </template>
    <template #[`item.category`]="{item}">
      <div class="mt-2">{{item.category}}</div>
      <div class="product-link-wrap" @click.stop="">
        <v-btn small class="deals-link primary--text p-0" style="padding: 0;max-height: 10px" plain :href="`https://${item.source_link}`" target="_blank">{{ item.source_link }}</v-btn>
      </div>
      <div class="mb-2">
        <v-btn small class="deals-link primary--text p-0" style="padding: 0;max-height: 10px" plain :to="{name: 'store-products', params: {slug: item.store_slug}}">
          {{ item.store }}
        </v-btn>
      </div>
    </template>
    <template #[`item.restricted`]="{item}">
      <div class="d-flex flex-column align-center">
        <div class="mb-1 d-flex pb-2">
          <red-flags
            :hasVariations="!!item.has_variations"
            :bundle="!!item.bundle"
            :isFragile="!!item.is_fragile"
            :isWater="!!item.is_meltable"
            :isBiohazard="!!item.is_haz_mat"
            :isPackage="!!item.is_oversize"
            :isAlert="!!item.is_alert"
            :isAmazon="!!item.is_amazon"
            :isAdult="!!item.is_adult"
            :isSmallAndLight="!!item.is_small_and_light"
          />
        </div>
        <v-btn
          v-if="$route.meta.user === 'unsigned'"
          block
          small
          color="primary"
          class="my-2 mx-0"
          @click.stop="$router.push({ name: 'auth-login'})"
        >
          {{ $t('login') }}
        </v-btn>
        <div 
          v-else-if="$route.meta.user === 'signed' && connectedAmzAccByRegions && marketplaceByRegion"
          class="text-center"
        >
          <v-btn
            color="success"
            outlined
            small
            @click.stop=""
            :to="{name: 'user-account', params: {tab: 'amazon-account'}}"
            v-if="!connectedAmzAccByRegions[marketplaceByRegion[item.marketplace_id]]"
          >
            {{ $t('check') }}
          </v-btn>
          <v-progress-circular
            v-else-if="item.restriction_data === false"
            indeterminate
            color="primary"
            size="20"
            width="3"
          />
          <v-tooltip v-else-if="item.restriction_data && item.restriction_data.reasons" top color="secondary" open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="rgb(177, 39, 4)"
                small
              >
                {{ icons.mdiLock }}
              </v-icon>
            </template>
            <span>{{ $t('You need approval') }}</span>
          </v-tooltip>
          <v-tooltip v-else-if="item.restriction_data && !item.restriction_data.error" top color="secondary" open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="success"
                small
              >
                {{ icons.mdiLockOpenVariant }}
              </v-icon>
            </template>
            <span>{{ $t('You can sell this product') }}</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template #[`item.cog`]="{item}">
      {{ cCodeToSymbol[item.currency_code] + Number(item.cog) }}
    </template>
    <template #[`item.price`]="{item}">
      <v-list-item class="font-weight-semibold">{{ `$${Number(item.price)}` }}</v-list-item>
    </template>
    <template #[`item.third_pl`]="{item}">
      {{ Number(item.third_pl) ? cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-' }}
    </template>
    <template #[`item.buy_box_price`]="{item}">
      {{ Number(item.buy_box_price) ? cCodeToSymbol[item.currency_code] + Number(item.buy_box_price) : '-' }}
    </template>
    <template #[`item.profit_fba`]="{item}">
      <div class="d-flex flex-column align-start">
        <v-tooltip v-if="item.profit_fba" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small label
              color="success"
              class="v-chip-light-bg success--text mb-1"
            >
              {{ Number(item.profit_fba) ? cCodeToSymbol[item.currency_code] + Number(item.profit_fba) : '-' }}
            </v-chip>
          </template>
          <span>{{ 'Profit'}}</span>
        </v-tooltip>
        <v-tooltip v-if="item.roi_fba" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ Number(item.roi_fba) }}%
            </span>
          </template>
          <span>{{ 'ROI'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.ref_fee_mod`]="{item}">
      <v-row>
        <v-col cols="6" class="pa-0">R{{item.ref_fee_mod}}%</v-col><v-col cols="6" class="pa-0">{{item.ref_fee}}</v-col>
        <v-col cols="6" class="pa-0">Sto</v-col> <v-col cols="6" class="pa-0">{{item.storage_fee}}</v-col>
        <v-col cols="6" class="pa-0">FBA</v-col> <v-col cols="6" class="pa-0">{{item.fba_fee}}</v-col>
      </v-row>
    </template>
    <template #[`item.store`]="{item}">
      <v-tooltip v-if="cutString(item.store).includes('...')" top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <router-link 
            @click.stop=""
            :to="{name: 'store-products', params: {slug: item.store_slug}}"
          >
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ cutString(item.store) }}
            </span>
          </router-link>
        </template>
        <span>{{ item.store }}</span>
      </v-tooltip>
      <v-tooltip v-else top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
            <router-link 
              v-bind="attrs"
              v-on="on"
              @click.stop=""
              :to="{name: 'store-products',params:{slug: item.store_slug}}"
            >
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ cutString(item.store) }}
            </span>
            </router-link>
        </template>
        <span>{{ 'Vendor'}}</span>
      </v-tooltip>
      <v-tooltip v-if="cutString(item.source_link).includes('...')" top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <div 
            v-bind="attrs"
            v-on="on"
          >
            <a 
              :href="'https://'+item.source_link"
              target="_blank"
              rel="nofollow"
              @click.stop=""
            >
              {{ cutString(item.source_link) }}
            </a>
          </div>
        </template>
        <span>{{ item.source_link }}</span>
      </v-tooltip>
      <v-tooltip v-else top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <div 
            v-bind="attrs"
            v-on="on"
          >
            <a 
              :href="'https://'+item.source_link"
              target="_blank"
              rel="nofollow"
              @click.stop=""
            >
              {{ cutString(item.source_link) }}
            </a>
          </div>
        </template>
        <span>{{ 'Source'}}</span>
      </v-tooltip>
    </template>
    <template #[`item.fba_offers`]="{item}">
      <div class="d-flex flex-column align-center">
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              label
              color="rgba(19, 156, 224, 0.3)"
              text-color="#EC6337"
              class="mb-1 deals-chip-offers fbm"
            >
              <b>FBM:{{ Number(item.fbm_offers) }}</b>
            </v-chip>
          </template>
          <span>{{ 'FBM quantity'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              label
              color="rgba(236, 99, 55, 0.3)"
              text-color="#EC6337"
              class="deals-chip-offers fba"
            >
              <b>FBA:{{ Number(item.fba_offers) }}</b>
            </v-chip>
          </template>
          <span>{{ 'FBA quantity'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.eye`]="{item}">
      <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :to="{ name: 'product', params: { id: item.id } }"
            >
              <v-icon size="26">
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ 'View'}}</span>
        </v-tooltip>
    </template>
    <template #[`item.access`]="{item}">
      <v-btn icon @click.stop="removeFromList(item.id)" class="mx-1">
        <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import {mdiEyeOutline, mdiSquareEditOutline, mdiDotsVertical, mdiMenuDown, mdiTrashCanOutline, mdiCartVariant, mdiCart, mdiLock, mdiLockOpenVariant} from '@mdi/js'
import {computed, ref, watch, onUnmounted} from "@vue/composition-api";
import { numberWithSpaces, kitCut, cCodeToSymbol } from "@/plugins/helpers"
import axios from "@axios";
import RedFlags from "../components/RedFlags";
import {useRouter} from "@core/utils";
import store from "@/store";
import {removeProductFromCheckout} from "@/plugins/tracking/events";
import {logEventAmplitude} from "@/plugins/tracking/amplitude";
import {logEventMixPanel} from "@/plugins/tracking/mixpanel";

export default {
  components: {
    RedFlags
  },
  filters: {
    numberWithSpaces,
  },
  setup() {
    const statusColor = {
      Verified: 'success',
      Unverified: 'warning',
    }
    const { router } = useRouter()
    const options = ref({})
    const loading = ref(true)
    const products = ref([])
    const totalProducts = ref(0)
    const duplicates = computed(() => store.state.cart.duplicates)
    const buyNowId = computed({
      get: () => store.state.cart.buyNowId,
      set: value => {
        store.commit('cart/SET_BUY_NOW_ID', value)
      },
    })
    const reload = ref(0)
    const selected = computed({
      get: () => store.state.cart.selectedCartItems,
      set: value => {
        store.commit('cart/SET_SELECTED_CART_IDS', value.map((e) => e.id))
        store.commit('cart/SET_SELECTED_CART_ITEMS', value)
      },
    })
    const removeFromList = (id) => {
      loading.value = true
      let event = removeProductFromCheckout(id)
      logEventAmplitude(event.name, event.props)
      logEventMixPanel(event.name, event.props)
      store.commit('cart/REMOVE_ID_FROM_SELECTED_CART_IDS', id)
      store.commit('cart/REMOVE_ITEM_FROM_SELECTED_CART_ITEMS', id)
      store.dispatch('cart/removeIdFromCart', {id}).then(() => {
        getProductsFromApi()
        if(!productsIdsInCart.value.length) store.dispatch('cart/setCartItems', [])
      })
    }
    const productsIdsInCart = computed(() => store.state.cart.cartIds)
    watch(() => [...productsIdsInCart.value], () => getProductsFromApi())
    const checkDuplicates = (e) => {
      if(duplicates.value.filter(el => el == e.id).length > 0) return 'duplicate'
    }
    const cutString = (text, limit = 11) => kitCut(text, limit)
    watch(() => options.value, () => {
      getProductsFromApi().then(() => {
        if(!buyNowId.value) {
          if(reload.value == 0) selected.value = products.value
        }else {
          selected.value = products.value.filter(el => el.id == buyNowId.value)
        }
        reload.value ++
      })
    })
    onUnmounted(() => {
      buyNowId.value = null
    })
    const getProductsFromApi = async () => {
      if(productsIdsInCart.value.length) {
        loading.value = true
        const {groupBy, groupDesc, itemsPerPage, multiSort, mustSort, page, sortBy, sortDesc} = options.value
        await axios.get('checkout-products', {
          params: {
            pagination: itemsPerPage,
            page,
            sort_field: sortBy[0],
            sort_dir: sortDesc[0] ? 'sortByDesc' : 'sortBy',
            ids: productsIdsInCart.value
          }
        }).then(res => {
          store.dispatch('cart/setDuplicates', res.data.duplicates)
          store.dispatch('cart/setMinTransactionAmonut', res.data.min_transaction_amount)
          products.value = res.data.data.map(e => Object.assign(e, {access: '', restricted: ''}))
          totalProducts.value = res.data.meta?.total
          store.dispatch('cart/setCartItems', res.data.data)
          loading.value = false
        }).catch(e => console.log(e))
      } else {
        totalProducts.value = 0
        products.value = []
        loading.value = false
      }
    }
    return {
      cutString,
      getProductsFromApi,
      checkDuplicates,
      removeFromList,
      duplicates,
      options,
      loading,
      totalProducts,
      productsIdsInCart,
      marketplaceByRegion: computed(() => store.state.connect.marketplaceByRegion),
      connectedAmzAccByRegions: computed(() => store.state.connect.connectedAmzAccByRegions),
      footerProps: {
        'items-per-page-options': store.getters['nav/itemsPerPageOptions']
      },
      headers: [
        {text: 'ID', value: 'id', width: 65, sortable: false,align: 'center'},
        {text: 'Keepa', value: 'keepa_image', sortable: false, width: 90, align: 'center'},
        // {text: 'Category', value: 'category', width: 120},
        {text: '30d avg BSR<br>Category', value: 'bsr_avg30',sortable: false, width: 100},
        {text: 'Profit<br>ROI', value: 'profit_fba',sortable: false, width: 50},
        {text: 'FBA/FBM<br>Sellers Qty', value: 'fba_offers',align: 'center',sortable: false, width: 80},
        // {text: '30d avg BSR', value: 'bsr_avg30', width: 130},
        // {text: '30d BSR Drops', value: 'sales_rank_drops30', width: 100},
        // {text: 'BuyBox price', value: 'buy_box_price', width: 80},
        // {text: 'COG', value: 'cog', width: 80},
        // {text: '3PL', value: 'third_pl', width: 80},
        {text: 'Red Flags<br>Restrictions', value: 'restricted',align: 'center',sortable: false, width: 100},
        {text: 'Store<br>Source', value: 'store', width: 70, sortable: false, align: 'center'},
        // {text: 'Profit', value: 'profit_fba', width: 85},
        // {text: 'ROI', value: 'roi_fba', width: 80},
        {text: 'Deal<br>Price', value: 'price', sortable: false, width: 40,align: 'center'},
        {value: 'eye', sortable: false, width: 40},
        {text: '', value: 'access', sortable: false, width: 20}
      ],
      productList: products,
      status: {
        0: 'Draft',
        1: 'Unverified',
        2: 'Verified',
        3: 'Rejected',
        4: 'Banned',
      },
      statusColor,
      selected,
      noKeepaImage: require('@/assets/images/app/pic_2.jpg'),
      cCodeToSymbol: cCodeToSymbol(),
      icons: {
        mdiEyeOutline,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMenuDown,
        mdiTrashCanOutline,
        mdiCartVariant,
        mdiCart,
        mdiLockOpenVariant,
        mdiLock
      }
    }
  },
}
</script>

<style lang="scss">
.cart-products-table {
  table {
    tr {
      th {
        .v-input--selection-controls__input::after {
          content: "All";
          margin: 5px 0px 0px 5px;
        }
      }
      th:first-child {
        width: 60px !important;
      }
      .v-simple-checkbox {
        align-items: center;
        display: flex;
        justify-content: center;
      }
      th, td {
        padding: 0 5px 0 5px !important;
      }
      td {
        height: 70px !important;
      }
      &.duplicate {
        background: #ff00001c !important;
      }
    }
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 1215px) {
    td:last-child,
    th:last-child,
    th:nth-last-child(2),
    td:nth-last-child(2) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 1;
      background-color: white;
    }

    th:nth-last-child(2),
    td:nth-last-child(2) {
      right: 46px;
      padding-right: 0;
    }

    td:last-child,
    th:last-child {
      right: 0;
      padding: 0 !important;
    }
    tr {
      &.duplicate {
        background: #ff00001c !important;
      }
    }
    td:nth-last-child(2) {
      box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.05);
    }
  }
}
.theme--dark {
  .cart-products-table {
    tr {
      &.duplicate {
        background: #ff00001c !important;
      }
    }
    @media only screen and (max-width: 1215px) {
      td:last-child,
      th:last-child,
      th:nth-last-child(2),
      td:nth-last-child(2) {
        background-color: #302d4b;
      }
      tr {
        &.duplicate {
          background: #ff00001c;
        }
      }
    }
  }
}
</style>