<template>
  <div>
    <v-card>
      <v-skeleton-loader
          v-if="productsLoading && balanceLoading"
          class="mx-auto"
          type="article, actions"
      ></v-skeleton-loader>
      <v-card-text v-else style="padding: 16px">
        <h4 class="title font-weight-bold">{{ $t('Order summary') }}</h4>
        <div class="d-flex my-2 justify-space-between">
          <span>{{ $t('Sub-total') }}</span>
          <span>US ${{ priceSum }}</span>
        </div>
        <div v-if="useBalance && balance > 0" class="d-flex mt-0 justify-space-between my-2">
          <span>{{ $t('Deals balance') }}</span>
          <span>US -${{ balance > priceSum ? priceSum : balance }}</span>
        </div>
        <div v-if="accountTopUp > 0 && total > 0" class="d-flex mt-0 justify-space-between my-2">
          <span>{{ $t('Account Top Up') }}</span>
          <span>US ${{ accountTopUp }}</span>
        </div>
        <v-divider></v-divider>
        <div class="d-flex my-2 justify-space-between">
          <h5 class="font-weight-bold"><b>{{ $t('Total') }}</b></h5>
          <h5 class="font-weight-bold"><b>US ${{ total }}</b></h5>
        </div>
        <v-btn block :disabled="Number(priceSum) == 0" color="primary" @click="buyDeals" v-if="total">{{ $t('Pay') }} ${{ total }} USD</v-btn>
        <v-btn block :disabled="Number(priceSum) == 0" :data="priceSum" color="primary" @click="buyDeals" v-else>{{ $t('Confirm') }}</v-btn>
      </v-card-text>
      <dialog-loading :dialog="PaymentInProgress"></dialog-loading>
      <done-modal />
    </v-card>
    <div class="deals-card-description">{{ $t("Purchases can only be refunded for Deal Credits that are directly transferred to your Deal Wallet. Cash refund not supported.") }}</div>
  </div>
</template>

<script>
import store from "@/store";
import {computed, getCurrentInstance, onBeforeMount, ref} from "@vue/composition-api";
import axios from "@axios";
import DialogLoading from "@/views/market/components/DialogLoading"
import DoneModal from "@/views/market/cart/DoneModal"
import storeProducts from "@/views/market/StoreProducts";
import {logEventAmplitude} from "@/plugins/tracking/amplitude";
import {logEventMixPanel} from "@/plugins/tracking/mixpanel";
import {checkoutBuyItemsClicked} from "@/plugins/tracking/events";

export default {
  name: "checkout",
  components: {
    DialogLoading,
    DoneModal
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const balance = ref(0)
    const minTransactionAmount = computed(() => store.state.cart.minTransactionAmount)
    const productsLoading = computed(() => store.state.cart.cartLoading)
    const balanceLoading = ref(true)
    const PaymentInProgress = ref(false)
    const priceSum = computed(() =>
      store.state.cart.selectedCartItems.reduce((previousValue, currentValue) => previousValue + currentValue.price, 0).toFixed(2)
    )
    const cartItems = computed(() => store.state.cart.selectedCartItems)
    const selectedPM = computed(() => store.state.cart.selectedPM)
    const useBalance = computed(() => store.state.cart.useBalance)
    const total = computed(() => {
      if (useBalance.value) {
        return balance.value < priceSum.value ? (priceSum.value - balance.value) < minTransactionAmount.value ? minTransactionAmount.value : (priceSum.value - balance.value).toFixed(2) : 0
      } else {
        return priceSum.value < minTransactionAmount.value ? minTransactionAmount.value : priceSum.value
      }
    })
    const accountTopUp = computed(() => !useBalance.value ? (minTransactionAmount.value - priceSum.value).toFixed(2) : (minTransactionAmount.value - (priceSum.value - balance.value)).toFixed(2))
    const buyDeals = () => {
      if (total.value && !selectedPM.value) {
        vm.$notify({
          group: 'deals',
          title: vm.$t("Checkout"),
          text: vm.$t("Add payment method first"),
          type: "warning"
        })
        document.getElementById('add-card').click();
        return
      }
      PaymentInProgress.value = true
      let ids = cartItems.value.map(el=>el.id)
      let event = checkoutBuyItemsClicked(ids, useBalance.value)
      logEventAmplitude(event.name, event.props)
      logEventMixPanel(event.name, event.props)
      axios.post('create-order', {
          ids,
          pm_id: selectedPM.value,
          use_balance: useBalance.value
        }
      ).then(() => {
        store.dispatch('cart/emptyCart')
        store.dispatch('account/getBalance')
        store.commit("cart/SET_DONE_MODAL", true)
        PaymentInProgress.value = false
      }).catch(err => {
        vm.$notify({
          group: 'deals',
          title: vm.$t("Checkout"),
          text: vm.$t(err.response.data.message),
          type: "error"
        })
        PaymentInProgress.value = false
      })
    }
    onBeforeMount(() => {
      axios.get('balance').then(res => {
        balance.value = res.data
        balanceLoading.value = false
      })
    })
    return {
      minTransactionAmount,
      accountTopUp,
      PaymentInProgress,
      priceSum,
      balance,
      total,
      productsLoading,
      balanceLoading,
      useBalance,
      buyDeals
    }
  }
}
</script>
