var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"cart-products-table",attrs:{"headers":_vm.headers,"checkbox-color":"primary","item-key":"id","show-select":"","items":_vm.productList,"options":_vm.options,"server-items-length":_vm.totalProducts,"loading":_vm.loading,"mobile-breakpoint":100,"footer-props":_vm.footerProps,"item-class":_vm.checkDuplicates,"hide-default-footer":true,"disable-pagination":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"deals-cart-empty"},[_c('v-icon',{staticClass:"mt-2",attrs:{"size":"100","color":"grey-400"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCart)+" ")]),_c('br'),_c('span',{staticClass:"d-block mb-2 font-weight-bold grey-400--text"},[_vm._v(" "+_vm._s(_vm.$t('Cart is empty'))+" ")])],1)]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticStyle:{"width":"100%"},attrs:{"type":"table-row-divider@2"}})]},proxy:true},{key:"header.profit_fba",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.price",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.bsr_avg30",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.restricted",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.fba_offers",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.store",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'product', params:{id: item.id}}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}},{key:"item.keepa_image",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'product', params: {id: item.id}}}},[_c('v-img',{staticStyle:{"margin-right":"0","box-shadow":"rgba(0, 0, 0, 0.15) 0px 2px 8px","border-radius":"5px"},attrs:{"max-height":"40","max-width":"80","src":item.keepa_image||_vm.noKeepaImage}})],1)]}},{key:"item.roi_fba",fn:function(ref){
var item = ref.item;
return [(item.roi_fba)?_c('span',[_vm._v(_vm._s(item.roi_fba)+"%")]):_vm._e()]}},{key:"item.bsr_avg30",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.bsr_avg30)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"v-chip-light-bg mb-1",class:item.bsr_avg30 > 0? 'success--text':'error--text',staticStyle:{"min-width":"40px","justify-content":"center","max-width":"fit-content"},attrs:{"small":"","label":"","color":item.bsr_avg30 > 0? 'success':'error'}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("numberWithSpaces")(item.bsr_avg30))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('90d avg BSR'))])]):_vm._e(),(item.category)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.category)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Category'))])]):_vm._e()],1)]}},{key:"item.sales_rank_drops30",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (Number(item.sales_rank_drops30))))+" ")]}},{key:"item.asin",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.product_url,"target":"_blank"}},[_vm._v(_vm._s(item.asin))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(item.category))]),_c('div',{staticClass:"product-link-wrap",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{staticClass:"deals-link primary--text p-0",staticStyle:{"padding":"0","max-height":"10px"},attrs:{"small":"","plain":"","href":("https://" + (item.source_link)),"target":"_blank"}},[_vm._v(_vm._s(item.source_link))])],1),_c('div',{staticClass:"mb-2"},[_c('v-btn',{staticClass:"deals-link primary--text p-0",staticStyle:{"padding":"0","max-height":"10px"},attrs:{"small":"","plain":"","to":{name: 'store-products', params: {slug: item.store_slug}}}},[_vm._v(" "+_vm._s(item.store)+" ")])],1)]}},{key:"item.restricted",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mb-1 d-flex pb-2"},[_c('red-flags',{attrs:{"hasVariations":!!item.has_variations,"bundle":!!item.bundle,"isFragile":!!item.is_fragile,"isWater":!!item.is_meltable,"isBiohazard":!!item.is_haz_mat,"isPackage":!!item.is_oversize,"isAlert":!!item.is_alert,"isAmazon":!!item.is_amazon,"isAdult":!!item.is_adult,"isSmallAndLight":!!item.is_small_and_light}})],1),(_vm.$route.meta.user === 'unsigned')?_c('v-btn',{staticClass:"my-2 mx-0",attrs:{"block":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'auth-login'})}}},[_vm._v(" "+_vm._s(_vm.$t('login'))+" ")]):(_vm.$route.meta.user === 'signed' && _vm.connectedAmzAccByRegions && _vm.marketplaceByRegion)?_c('div',{staticClass:"text-center"},[(!_vm.connectedAmzAccByRegions[_vm.marketplaceByRegion[item.marketplace_id]])?_c('v-btn',{attrs:{"color":"success","outlined":"","small":"","to":{name: 'user-account', params: {tab: 'amazon-account'}}},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t('check'))+" ")]):(item.restriction_data === false)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"20","width":"3"}}):(item.restriction_data && item.restriction_data.reasons)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"rgb(177, 39, 4)","small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiLock)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('You need approval')))])]):(item.restriction_data && !item.restriction_data.error)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiLockOpenVariant)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('You can sell this product')))])]):_vm._e()],1):_vm._e()],1)]}},{key:"item.cog",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.cCodeToSymbol[item.currency_code] + Number(item.cog))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(("$" + (Number(item.price)))))])]}},{key:"item.third_pl",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.third_pl) ? _vm.cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-')+" ")]}},{key:"item.buy_box_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.buy_box_price) ? _vm.cCodeToSymbol[item.currency_code] + Number(item.buy_box_price) : '-')+" ")]}},{key:"item.profit_fba",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-start"},[(item.profit_fba)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"v-chip-light-bg success--text mb-1",attrs:{"small":"","label":"","color":"success"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(Number(item.profit_fba) ? _vm.cCodeToSymbol[item.currency_code] + Number(item.profit_fba) : '-')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Profit'))])]):_vm._e(),(item.roi_fba)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(Number(item.roi_fba))+"% ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('ROI'))])]):_vm._e()],1)]}},{key:"item.ref_fee_mod",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_vm._v("R"+_vm._s(item.ref_fee_mod)+"%")]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_vm._v(_vm._s(item.ref_fee))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_vm._v("Sto")]),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_vm._v(_vm._s(item.storage_fee))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_vm._v("FBA")]),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_vm._v(_vm._s(item.fba_fee))])],1)]}},{key:"item.store",fn:function(ref){
var item = ref.item;
return [(_vm.cutString(item.store).includes('...'))?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":{name: 'store-products', params: {slug: item.store_slug}}},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.store))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.store))])]):_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',_vm._g(_vm._b({attrs:{"to":{name: 'store-products',params:{slug: item.store_slug}}},on:{"click":function($event){$event.stopPropagation();}}},'router-link',attrs,false),on),[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.store))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Vendor'))])]),(_vm.cutString(item.source_link).includes('...'))?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{attrs:{"href":'https://'+item.source_link,"target":"_blank","rel":"nofollow"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.cutString(item.source_link))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.source_link))])]):_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{attrs:{"href":'https://'+item.source_link,"target":"_blank","rel":"nofollow"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.cutString(item.source_link))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Source'))])])]}},{key:"item.fba_offers",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mb-1 deals-chip-offers fbm",attrs:{"small":"","label":"","color":"rgba(19, 156, 224, 0.3)","text-color":"#EC6337"}},'v-chip',attrs,false),on),[_c('b',[_vm._v("FBM:"+_vm._s(Number(item.fbm_offers)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s('FBM quantity'))])]),_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"deals-chip-offers fba",attrs:{"small":"","label":"","color":"rgba(236, 99, 55, 0.3)","text-color":"#EC6337"}},'v-chip',attrs,false),on),[_c('b',[_vm._v("FBA:"+_vm._s(Number(item.fba_offers)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s('FBA quantity'))])])],1)]}},{key:"item.eye",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{ name: 'product', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s('View'))])])]}},{key:"item.access",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFromList(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }