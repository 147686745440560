<template>
  <div v-if="!loading">
    <transition name="backdrop" appear>
      <v-alert v-if="(isAmazonAlert || !closeEv) && !connectedAmzAccByRegions.na" color="white" :colored-border="true" class="deals-alert">
        <div class="deals-alert-text">
          <v-icon size="20" color="warning" class="mr-2 mb-1">{{ icons.mdiAlert }}</v-icon>
          <b class="deals-alert-warning">{{ $t('Warning!') }} </b>
          {{ $t('These deals may not suite your Amazon Seller Account. Please connect your Amazon Seller Account to check your ability to sell products from these deals.') }}
          <router-link style="white-space: nowrap" :to="{name: 'user-account', params: {tab: 'amazon-account'}}">
            {{ $t('Connect Amazon Seller Account') }}
          </router-link>
        </div>
        <div v-if="closeEv">
          <v-btn
            class="mr-2"
            icon
            small
            @click="isAmazonAlert = !isAmazonAlert"
          >
            <v-icon size="20">{{ icons.mdiWindowClose }}</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </transition>
  </div>
</template>

<script>
import { mdiWindowClose, mdiAlert } from '@mdi/js';
import { computed, onBeforeMount } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    closeEv: {
      type: Boolean,
      default: true
    },
    loadConnect: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const isAmazonAlert = computed({
      get: () => store.state.nav.isAmazonAlert,
      set: value => {
        store.commit('nav/SET_IS_AMAZON_ALERT', value)
      },
    })
    if(props.loadConnect) {
      onBeforeMount(() => store.dispatch('connect/connectedAmzAccounts'))
    }
    return {
      isAmazonAlert,
      loading: computed(() => store.state.connect.loading),
      connectedAmzAccByRegions: computed(() => store.state.connect.connectedAmzAccByRegions),
      icons: {
        mdiAlert,
        mdiWindowClose
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.backdrop-leave-active,.content-leave-active {
  animation: fade-out .75s;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>